import "./index.scss";

import type { ComponentProps } from "react";
import { defineMessages, useIntl } from "react-intl";

import SectionHeader from "common/form/sub_form/section/header";
import NotaryInstructionsGrid from "common/details/instructions/instruction";
import { usePermissions } from "common/core/current_user_role";

const messages = defineMessages({
  notaryNoteSubHeaderTitle: {
    id: "9d1f4da7-25e6-42c1-8947-296c144bd006",
    defaultMessage: "Do you need to include any special instructions for the notary?",
  },
  selfNoteSubHeaderTitle: {
    id: "853890fc-3ec1-42c2-9d1e-30382f70dace",
    defaultMessage: "Do you want to include any notes for yourself?",
  },
  notaryNoteSubHeaderSubtitle: {
    id: "92b66e68-f016-4942-8c16-045ccba7a6b0",
    defaultMessage:
      "You can leave a note here if the signing requires any special instructions and the notary will be able to review it before they begin the signing process.",
  },
  selfNoteSubHeaderSubtitle: {
    id: "e643be10-e31b-4b6f-83eb-c41cf560659a",
    defaultMessage:
      "You can leave a note here if the signing requires any special instructions and you will be able to see it before beginning the signing process.",
  },
});

type Props = {
  onUpdateNotaryInstruction: (instruction: { id: string; text: string }) => void;
  onDeleteNotaryInstruction: (id: string) => void;
  instructions: ComponentProps<typeof NotaryInstructionsGrid>["instructions"];
  selfNotes?: boolean;
};

export default function NotaryNotesSection({
  onUpdateNotaryInstruction,
  onDeleteNotaryInstruction,
  instructions,
  selfNotes = false,
}: Props) {
  const intl = useIntl();
  const hasNotes = instructions.length > 0;
  const { hasPermissionFor } = usePermissions();

  return (
    <div className="NotaryNotesSection">
      <SectionHeader
        title={
          selfNotes
            ? intl.formatMessage(messages.selfNoteSubHeaderTitle)
            : intl.formatMessage(messages.notaryNoteSubHeaderTitle)
        }
        subtitle={
          hasNotes
            ? null
            : selfNotes
              ? intl.formatMessage(messages.selfNoteSubHeaderSubtitle)
              : intl.formatMessage(messages.notaryNoteSubHeaderSubtitle)
        }
      />
      <div className="SubFormSection">
        <NotaryInstructionsGrid
          instructions={instructions}
          onDelete={onDeleteNotaryInstruction}
          onSave={onUpdateNotaryInstruction}
          allowChanges={hasPermissionFor("editOrganizationTransactions")}
        />
      </div>
    </div>
  );
}
