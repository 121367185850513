import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { OrganizationTransactionVariant } from "graphql_globals";
import { Card } from "common/core/card";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { Heading, Paragraph } from "common/core/typography";
import IdentityReportIllustration from "assets/images/identity-report.svg";
import Svg from "common/core/svg";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { getParentPathByOffset } from "util/location";

import type {
  DocumentBundleForDocumentPreview_documents_edges_node as Document,
  DocumentBundleForDocumentPreview as DocumentBundle,
} from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type PropertyInfoContainerProps = {
  bundle: DocumentBundle;
};

function EmptyState() {
  const emptyStateMessage = (
    <FormattedMessage
      id="fcbd84fa-b398-4d64-9090-4bccc0512688"
      defaultMessage="No documents have been added to this transaction yet"
    />
  );
  return (
    <div className={Styles.emptyStateContainer}>
      <Svg src={IdentityReportIllustration} alt="" />
      <Heading level="h2" textStyle="headingFour">
        <FormattedMessage
          id="ba17a0f2-0828-4fc7-b775-7632bb2d64d2"
          defaultMessage="Come back later..."
        />
      </Heading>
      <Paragraph textColor="subtle">{emptyStateMessage}</Paragraph>
    </div>
  );
}

function DocumentPreview({ documentUrl }: { documentUrl: string | null }) {
  const { pathname } = useLocation();
  const path = getParentPathByOffset(pathname, 1);
  const documentTabUrl = `${path}/document`;

  if (!documentUrl) {
    return null;
  }

  return (
    <div className={Styles.pdfPreviewContainer}>
      <div className={Styles.pdfPreview}>
        <PDFViewer url={documentUrl} />
      </div>
      <div className={Styles.pdfPreviewFooter}>
        <ButtonStyledLink variant="tertiary" buttonColor="action" to={documentTabUrl}>
          <FormattedMessage
            id="44231c54-47a7-4854-a430-6f27431a8313"
            defaultMessage="View documents"
          />
        </ButtonStyledLink>
      </div>
    </div>
  );
}

export function DocumentPreviewContainer({ bundle }: PropertyInfoContainerProps) {
  const transactionType = bundle.transaction.transactionVariant;
  const isIdentifyTransaction = transactionType === OrganizationTransactionVariant.IDENTIFY;
  const hasDocuments = bundle.documents.edges.length > 0;
  const currentDocument = hasDocuments ? bundle.documents.edges[0].node : null;

  const getDocumentAssetUrl = (document: Document) => {
    if (document.finalAsset) {
      return document.finalAsset.url;
    }
    return document.s3OriginalAsset!.url;
  };

  // TODO: BIZ-6882 - add preview for an identify transaction
  if (isIdentifyTransaction) {
    return null;
  }

  return (
    <Card noMargin fullWidth className={currentDocument ? Styles.docPreviewContainer : undefined}>
      {currentDocument ? (
        <DocumentPreview documentUrl={getDocumentAssetUrl(currentDocument)} />
      ) : (
        <EmptyState />
      )}
    </Card>
  );
}
